<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <el-date-picker v-model="beginTime" placeholder="开始日期" style="width: 160px;"
                        @change="loadOrderItems(true)" />
                    <el-date-picker v-model="endTime" placeholder="开始日期" class="ml-2" style="width: 160px;"
                        @change="loadOrderItems(true)" />
                    <search-box v-model="key" class="ml-2" style="width: 280px;" @search="loadOrderItems(true)" />
                </div>
            </div>
            <el-table :data="orderItems.data" class="mt-2">
                <el-table-column width="90">
                    <template #default="scope">
                        <el-image :src="scope.row.productSnapshot.pictureUrl"
                            :preview-src-list="[scope.row.productSnapshot.pictureUrl]" fit="fill"
                            style="width: 64px; height: 64px;" />
                    </template>
                </el-table-column>
                <el-table-column label="商品">
                    <template #default="scope">
                        <div>{{scope.row.productSnapshot.name}}</div>
                        <div class="text-info">
                            {{scope.row.number}}（{{scope.row.productSnapshot.unit}}）共
                            {{scope.row.totalPrice}} 元
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="买家" align="right" width="120">
                    <template #default="scope">
                        <div>{{scope.row.buyerName}}</div>
                        <div class="text-info">{{$moment.sd(scope.row.paymentTime)}}</div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="orderItems.totalRecords"
                :current-page="orderItemsPagination.currentPage" :page-size="orderItemsPagination.pageSize" class="mt-3"
                @current-change="orderItemsPaginationCurrentChange" />
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                beginTime: new Date(new Date().getFullYear(), 0, 1),
                endTime: new Date(),
                key: null,
                orderItems: {},
                orderItemsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        methods: {
            async loadOrderItems(resetPage = false) {
                if (resetPage) {
                    this.orderItemsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Settlement/GetNoSettledOrderItems', {
                    params: {
                        supplierId: this.$store.state.user.id,
                        beginTime: this.$moment(this.beginTime).format('YYYY/M/D'),
                        endTime: this.$moment(this.endTime).format('YYYY/M/D'),
                        key: this.key,
                        pageIndex: this.orderItemsPagination.currentPage - 1,
                        pageSize: this.orderItemsPagination.pageSize,
                    }
                });
                this.orderItems = response.data;
            },
            orderItemsPaginationCurrentChange(page) {
                this.orderItemsPagination.currentPage = page;
                this.loadOrderItems();
            },
        },
        created() {
            this.loadOrderItems();
        },
    };
</script>